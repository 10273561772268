import React, {Fragment, useState} from 'react';
import {E_AUDIT_CLAIMED_STATUS, Message} from '@src/types';
import {ORDER_API} from '@api/Order';
import {useNavigate} from 'react-router-dom';

import {translate, translateV2} from '@src/helpers';
import {Dropdown, DropdownButton, Image} from 'react-bootstrap';
import _ from "lodash-es";
import {useAppDispatch, useAppSelector} from "@redux/hooks/hooks";
import {userState} from "@redux/reducers/userSlice";
import {showMessage} from "@redux/reducers/messageSlice";
import NoConnectedOrders from "@v2pages/OrderDetail/Extra/NoConnectedOrders";
import {checkIcon, getOrderProcessScanType} from "@v2pages/OrderDetail/Extra/OrderProcessHelper";
import "./custom-toggle.css";
import {COUNTRY_FLAGS} from "@assets/CountryFlags";
import ProcessInputProductNames from "@v2pages/OrderDetail/Extra/ProcessInputProductNames";
import ProcessDateTimeComponent from "@v2pages/OrderDetail/Extra/ProcessDateTimeComponent";
import OrderProcessCtas from "@v2pages/OrderDetail/Extra/OrderProcessCtas";
import {ASSETS} from "@assets/Assets";
import DropdownItem from "react-bootstrap/DropdownItem";
import LoadingV2 from "@v2components/Loading/LoadingV2";
import {isMobile} from "react-device-detect";

type DetailViewProcessProps = {
  data: any,
  order?: any,
  className?: string | ""
  classNameWrapper?: string | ""
  editable?: any,
  isCompleted?: any,
  currentHistory?: any,
  item?: any,
  onClickScanType?: any,
  onClickConnectedOrders?: any,
  onClickLinkOrders?: any,
  onClickView?: any,
  onClickEdit?: any,
  onClickDelete?: any
}

const OrderProcessDetailV3: React.FC<DetailViewProcessProps> = ({

                                                                  className = "",
                                                                  classNameWrapper = "",

                                                                  editable,
                                                                  isCompleted,
                                                                  currentHistory,
                                                                  item,
                                                                  onClickScanType,
                                                                  onClickConnectedOrders,
                                                                  onClickLinkOrders,
                                                                  onClickView,
                                                                  onClickEdit,
                                                                  onClickDelete
                                                                }: any) => {

  const userInfo = useAppSelector(userState);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [connectedOrdersLoading, setConnectedOrdersLoading] = useState(false);
  const [connectedOrders, setConnectedOrders] = useState<any>([])
  const [showConnectedOrders, setShowConnectedOrders] = useState(false)
  const [showDetailProcessMobile, setShowDetailProcessMobile] = useState(false);

  const canNotCreateOrderProcess = () => {
    return Boolean(!editable && !userInfo.is_company_admin) || isCompleted;
  }

  const index = _.uniqueId(`process_detail_`);

  const getClaimedStatusLabel = (claimed_status: E_AUDIT_CLAIMED_STATUS) => {
    let label = "LABEL.SELF_REPORTED";
    switch (claimed_status) {
      case E_AUDIT_CLAIMED_STATUS.CLAIMED: {
        label = "LABEL.CLAIMED"
        break;
      }
      case E_AUDIT_CLAIMED_STATUS.VERIFIED: {
        label = "LABEL.VERIFIED"
        break;
      }
      case E_AUDIT_CLAIMED_STATUS.DISPUTE: {
        label = "LABEL.DISPUTE"
        break;
      }
      case E_AUDIT_CLAIMED_STATUS.SELF_REPORTED: {
        label = "LABEL.SELF_REPORTED"
        break;
      }
    }
    return label;
  }

  const getPartnerStatusIcon = (claimed_status: E_AUDIT_CLAIMED_STATUS) => {
    try {
      if (!claimed_status) claimed_status = E_AUDIT_CLAIMED_STATUS.SELF_REPORTED;
      const partnerStatus = getOrderProcessScanType(claimed_status)
      return partnerStatus.icon
    } catch (error) {
      console.error(error.message)
      return checkIcon();
    }
  }

  const getPartnerStatusClass = (claimed_status: E_AUDIT_CLAIMED_STATUS) => {
    try {
      if (!claimed_status) claimed_status = E_AUDIT_CLAIMED_STATUS.SELF_REPORTED;
      const partnerStatus = getOrderProcessScanType(claimed_status)
      return partnerStatus.badgeClassName
    } catch (error) {
      console.error(error.message)
      return ""
    }
  }

  const fetchConnectedOrders = async (data: { process_id: number | string }) => {
    try {
      const r = await ORDER_API.getConnectedOrders(data)
      return r
    } catch (err) {
      console.error(err.message)
      dispatch(showMessage({
        isShow: true,
        title: "LABEL.ORDER_PROCESS.CONNECTED_ORDERS",
        type: Message.ERROR,
        message: err.message
      }))
      return []
    }
  }

  const InputOrders = () => {
    return !item.input_orders_count
      ? <NoConnectedOrders/>
      : <Dropdown
        className={`fibre-dropdown-corders ${showConnectedOrders ? "show" : ""}`}
        onSelect={(eventKey: any, event: Object) => {

        }}
        onToggle={async (isOpen: boolean,
                         event,
                         metadata: {
                           source: 'select' | 'click' | 'rootClose' | 'keydown'
                         }) => {
          try {
            setConnectedOrdersLoading(true)
            if (_.size(connectedOrders) <= 0) {

              const {data} = await fetchConnectedOrders({process_id: item?.process_id});
              if (data) {
                setConnectedOrders([...data])
              }
              if (_.isFunction(onClickConnectedOrders)) {
                onClickConnectedOrders({
                  data: [...data]
                });
              }
            }
            setShowConnectedOrders(!showConnectedOrders)
            setShowDetailProcessMobile(!showConnectedOrders)
          } catch (err) {
            console.error(err)
          } finally {
            setConnectedOrdersLoading(false)
          }
        }}
      >
        <Dropdown.Toggle variant="success"
                         className={"w-100 fz-12 "}
                         disabled={connectedOrdersLoading}
        >
          {translateV2("LABEL.ORDER_PROCESS.CONNECTED_ORDERS_NUMBER", "", {
            total: item.input_orders_count
          })}
        </Dropdown.Toggle>

      </Dropdown>
  }

  const journeyItemClass = () => {
    return `journey-item ${className} ${showDetailProcessMobile ? " mexpanded " : ""} ${showConnectedOrders ? " expanded " : ""} ${!item?.connected_orders?.length ? "" : "has-connected-orders"}`;
  }


  const toggleJourneyItemMobile = () => {
    try {
      setShowDetailProcessMobile(!showDetailProcessMobile)
    } catch (e) {
      console.error(e)
    }
  }

  const getCountryFlag = (alpha2Code = 0) => {
    if (alpha2Code) return COUNTRY_FLAGS[alpha2Code]
    return false
  }

  const toggleConnectedOrders = () => {

  }

  return (

    <Fragment>
      <div className={`journey-item-wrapper ${classNameWrapper} ${showConnectedOrders ? " expanded " : ""}`}>
        <div
          className={journeyItemClass()}>

          <div className="journey-item-toggle-mobile">
            <span onClick={() => {
              toggleJourneyItemMobile()
            }}/>
          </div>

          <div className="journey-item-process">
            <div className="break-word order-processes-name">
              <b>{item.process_name}</b>
              <ProcessInputProductNames inputProductNames={[...item.inputProductNames]}/>
            </div>
          </div>

          <div className="journey-item-time">
            <p className={"journey-header-mobile"}>{translateV2("LABEL.AUDIT_DEADLINE")}</p>
            <ProcessDateTimeComponent dateTime={item.process_date}/>
          </div>

          <div className="journey-item-audits">
            <p className={"journey-header-mobile"}>{translateV2("LABEL.ORDER_PROCESS.AUDITS")}</p>
            {item.scanned_times_and_scan_count}
          </div>

          <div className="journey-item-location">
            <p className={"journey-header-mobile"}>{translateV2("LABEL.ORDER_PROCESS.LOCATION")}</p>

            <span>{item.process_location}</span>


            {/*{getCountryFlag(item.alpha2Code) &&*/}
            {/*    <span className={"journey-item-location-flag rounded-circle shadow mr-2"}*/}
            {/*          style={{*/}
            {/*            backgroundImage: `url("${getCountryFlag(item.alpha2Code)}")`*/}
            {/*          }}*/}
            {/*    />}*/}

          </div>

          <div className="journey-item-manufacturer">
            <p
              className={"journey-header-mobile"}>{translateV2("LABEL.ORDER.TABLE.MANUFACTURER")}</p>
            <p>{item?.process_manufacturer_name}</p>
            {/*<span className={statusDetails.badgeClassName}>*/}
            {/*                  {statusDetails.icon}&nbsp;*/}
            {/*  {translateV2( statusDetails.label)}*/}
            {/*              </span>*/}
          </div>

          <div className="journey-item-scantype">
            <p
              className={"journey-header-mobile"}>{translateV2("LABEL.ORDER_PROCESS.SCAN_TYPE")}</p>
            <span style={{
              cursor: "inherit",
              padding: "3px 10px",
            }}
                  className={getPartnerStatusClass(item.claimed_status) + " fz-12"}
                  onClick={() => {
                    if (_.isFunction(onClickScanType)) onClickScanType()
                  }}>{getPartnerStatusIcon(item.claimed_status)}
              {translateV2(getClaimedStatusLabel(item.claimed_status))}</span>
          </div>

          <div className="journey-item-files">
            <p
              className={"journey-header-mobile"}>{translateV2("LABEL.ORDER.TABLE.FILES")}</p>

            {
              item.process_certificate_count === 0
                ? <div className={"order-processes-certificate"}>
                  <Image className="opacity-40" src={ASSETS.IconOrderFileBlackV2} alt={"Files"}
                         width={21}/>
                  <span
                    className={"order-processes-certificate-count opacity-40"}>{item.process_certificate_count}</span>
                </div>
                : <DropdownButton className="custom-dropdown-button" drop="up"
                                  variant="secondary" title={<div className={"order-processes-certificate"}>
                  <Image src={ASSETS.IconOrderFileBlackV2} alt={"Files"}
                         width={21}/>
                  <span
                    className={"order-processes-certificate-count"}>{item.process_certificate_count}</span>
                </div>}>
                  {item.process_certificates.map(certificate => <DropdownItem
                    key={_.uniqueId(`process-certificate-${certificate.id}`)}
                    target='_blank'
                    href={certificate.path}>{certificate.name}</DropdownItem>
                  )}
                </DropdownButton>
            }
          </div>

          <div className="journey-item-corders">
            <InputOrders/>
          </div>

          <div className="journey-item-actions">
            <OrderProcessCtas
              item={item}
              onClickEdit={onClickEdit}
              onClickLinkOrders={onClickLinkOrders}
              onClickDelete={onClickDelete}
            />

            <Dropdown
              className={"fibre-dropdown-trans"}
              alignRight={true}
            >
              <Dropdown.Toggle variant="success"
                               className={"w-100"}
              >
                <Image src={ASSETS.IconExpanderV2} alt={"Expand"}/>
              </Dropdown.Toggle>

              <Dropdown.Menu
                className={isMobile ? " pl-2 pr-2 " : ""}
              >
                <OrderProcessCtas
                  item={item}
                  onClickEdit={onClickEdit}
                  onClickLinkOrders={onClickLinkOrders}
                  onClickDelete={onClickDelete}
                />
              </Dropdown.Menu>
            </Dropdown>

          </div>

        </div>

        {
          connectedOrdersLoading
            ? <div className={"position-relative"} style={{minHeight: "100px"}}>
              <LoadingV2 style={{
                backgroundColor: "transparent"
              }}/>
            </div>
            : showConnectedOrders && _.map(connectedOrders, (process: any) => {
            return <OrderProcessDetailV3 key={process.process_id} item={process}/>
          })
        }

      </div>

    </Fragment>


  );
}

export default OrderProcessDetailV3;