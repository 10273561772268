import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '@redux/hooks/store';
import {DEFAULT_APP_CONFIG} from "@src/variables";
import {COMMON_API} from "@api/Common";

export interface ConfigInterface {
    app_name: string,
    audit_media_accepted_file_extension: string
    audit_attached_file_accepted_extension: string
    audit_media_max_number_of_file: string | any
    audit_media_max_upload_file_size: string | any
    datetime_format: string
    minimum_search_character: string | any
    missmatch_location_distance: string | any
    notification_refresh_interval: number
    order_scanner_label: string | any
    order_scanner_allowed: boolean | any
    frequency_of_scan: string | any
    frequency_of_scan_period: string | any
    zendesk_help_url: string | any
    webapp_policy_url: string | any
    android_app_url: string | any
    ios_app_url: string | any
    android_app_toggle: string | boolean
    ios_app_toggle: string | boolean
    enable_request_a_setup_call: string | boolean
    multiple_id_detection_minimum_blending_percentage: number | any
    product_media_max_number_of_file: string | any
    product_media_max_upload_file_size: string | any
    fibretrace_accountant_contact_email: string | any
    st_scanner_code_max_length: number | any
    google_tag_manager_id: string | any
    google_analytic_id: string | any
    ecommerce_checkout: boolean
}

const initialState: ConfigInterface = DEFAULT_APP_CONFIG

export const getBasicConfigAsync = createAsyncThunk(
    'config/getBasicConfig',
    async () => {
        const response = await COMMON_API.getConfig()
        return response.data;
    }
);

export const configSlice = createSlice({
    name: 'config',
    initialState:{
        initialized: false,
        ...initialState
    },
    reducers: {
        setConfig: (state, action: PayloadAction<ConfigInterface>) => {
            Object.assign(state, action.payload)
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getBasicConfigAsync.fulfilled, (state, action) => {
                Object.assign(state,action.payload)
                state.initialized = true
            });
    },
});

export const {setConfig} = configSlice.actions;

export const configState = (state: RootState) => state.config;

export default configSlice.reducer;
