import {createAsyncThunk} from "@reduxjs/toolkit";
import {AppDispatch, RootState} from "@redux/hooks/store";
import {ORDER_API} from "@api/Order";
import {COMPANY_API} from "@api/Company";
import {companyPageSlice} from "@redux/reducers/companySlice";
import {AUDIT_TEMPLATE_API} from "@api/AuditTemplate";
import {Message} from "@src/types";
import {showMessage} from "@redux/reducers/messageSlice";
import {fetchAuditTemplatesAsync} from "@redux/reducers/auditTemplateSlice";
import {PROCESS_API} from "@api/Process";
import {USER_API} from "@api/User";
import {userLogoutAsync, UserUpdateInterface} from "@redux/reducers/userSlice";
import {blockSlice} from "@redux/reducers/blockSlice";

interface ThunkAPI {
    dispatch: AppDispatch
    state: RootState,
    extra?: any
}

interface RedirectThunkAPI extends ThunkAPI {
  extra: any
}

interface CompanyUnsavedInterface {
  selected: string,
  company: object
}

export const downloadOrderFiles = createAsyncThunk<void, string, ThunkAPI>(
  'order/downloadFiles',
  async (orderCode, {dispatch, getState}) => {
    const res = await ORDER_API.getOrderFiles({code: orderCode});

    const {error, message} = res;

    dispatch(showMessage({
      isShow: true,
      type: !error ? Message.SUCCESS : Message.ERROR,
      title: "",
      message: message || error
    }))

    return res.data
  }
);

export const savePendingCompanyObjAsync = createAsyncThunk<void, CompanyUnsavedInterface, ThunkAPI>(
  'companyPage/savePending',
  async ({selected, company}, {dispatch, getState}) => {
    const response = await COMPANY_API.updateCompanyV2({...company})
    if (response.data) {
      dispatch(companyPageSlice.actions.setCompanyPage({
        selected,
        canNavigate: true
      }))

    }
    return response.data;
  }
);

export const deleteAuditTemplateAsync = createAsyncThunk<object, any, ThunkAPI>(
  'auditTemplate/deleteOne',
  async (item, {dispatch, getState}) => {
    const store = getState()
    const req = await AUDIT_TEMPLATE_API.deleteOne(item);
    const {message, error, exception} = req

    dispatch(showMessage({
      isShow: true,
      type: !error && !exception ? Message.SUCCESS : Message.ERROR,
      title: "",
      message: message || error
    }))

    dispatch(fetchAuditTemplatesAsync({
      selected: store.auditTemplate.search.selected,
      queries: store.auditTemplate.search.queries,
    }))

    return req
  }
);

export const publishTransactionCertificate = createAsyncThunk<void, string, ThunkAPI>(
  'orderProcess/publishTransCert',
  async (orderCode, {dispatch, getState}) => {
    if (!orderCode) return false;

    const res = await PROCESS_API.publishTransactionCertificate({orderCode});

    return res.data
  }
);

export const updateUserAndLogoutAsync = createAsyncThunk(
  'user/updateUserAndLogout',
  async (params: UserUpdateInterface, {dispatch}) => {
    blockSlice.actions.block(true);

    const {data, message, error} = await USER_API.updateUserV2({...params})
    if (!error) {
      dispatch(userLogoutAsync())
    }
    return data;
  }
);

export const cancelPendingCompanyObjAsync = createAsyncThunk<void, CompanyUnsavedInterface, ThunkAPI>(
  'companyPage/cancelPending',
  async ({selected, company}, {dispatch, getState}) => {
    dispatch(companyPageSlice.actions.setCompanyPage({
      selected,
      canNavigate: true
    }))
  }
);

export const downloadAuditCSV = createAsyncThunk<void, string, ThunkAPI>(
  'order/downloadAuditCSV',
  async (orderCode, {dispatch, getState}) => {
    const res = await ORDER_API.getAuditCSV({code: orderCode});
    const {error, message} = res;

    dispatch(showMessage({
      isShow: true,
      type: !error ? Message.SUCCESS : Message.ERROR,
      title: "",
      message: message || error
    }))

    return res.data
  }
);