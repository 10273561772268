import React, {useRef, useState} from 'react';
import Tabs from '@components/Tabs/Tabs';
import {E_AUDIT_CLAIMED_STATUS, ITabs, Message, StepTabs, USER_ACTION} from '@src/types';
import {ORDER_API} from '@api/Order';
import {useNavigate} from 'react-router-dom';

import {translateV2} from '@src/helpers';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faDownload, faInfoCircle, faSpinner} from '@fortawesome/free-solid-svg-icons';
import {Button, Modal} from 'react-bootstrap';
import Loading from '@components/Loading/Loading';
import _ from "lodash-es";
import {useAppDispatch, useAppSelector} from "@redux/hooks/hooks";
import {languageState} from "@redux/reducers/languageSlice";
import {isMobile} from "react-device-detect";
import {userState} from "@redux/reducers/userSlice";
import {Steps} from "intro.js-react";
import LoadingRowV2 from "@v2components/Loading/LoadingRowV2";
import {EComponents, panelSlice} from "@redux/reducers/panelSlice";
import {
  fetchOrderHistoriesAsync,
  IHistory,
  orderDetailState,
  orderProcessesState
} from "@redux/reducers/orderDetailPage";
import POSMapV2 from "@v2components/PosMap/POSMapV2";
import ModalLinkedOrders from "@v2pages/OrderDetail/Extra/ModalLinkedOrders";
import {linkedOrdersMessageSlice} from "@redux/reducers/linkedOrderMessageSlice";
import {confirmationMessageSlice} from "@redux/reducers/confirmationMessageSlice";
import OrderProcessDetailV3 from "@v2pages/OrderDetail/OrderProcessDetailV3";


const checkIcon = () => {
  return <span className={"mr-1"}>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
             fill="none"
             style={{clipPath: "url(#clip0_2_654)"}}>
        <g>
            <path
              d="M11.0597 5.0535L6.66634 9.44683L4.27301 7.06016L3.33301 8.00016L6.66634 11.3335L11.9997 6.00016L11.0597 5.0535ZM7.99967 1.3335C4.31967 1.3335 1.33301 4.32016 1.33301 8.00016C1.33301 11.6802 4.31967 14.6668 7.99967 14.6668C11.6797 14.6668 14.6663 11.6802 14.6663 8.00016C14.6663 4.32016 11.6797 1.3335 7.99967 1.3335ZM7.99967 13.3335C5.05301 13.3335 2.66634 10.9468 2.66634 8.00016C2.66634 5.0535 5.05301 2.66683 7.99967 2.66683C10.9463 2.66683 13.333 5.0535 13.333 8.00016C13.333 10.9468 10.9463 13.3335 7.99967 13.3335Z"
              fill="#525252"/>
        </g>
        <defs>
            <clipPath id="clip0_2_654">
                <rect width="16" height="16" fill="white"/>
            </clipPath>
        </defs>
    </svg></span>
}

// fib-badge-audit-selfreport
// fib-badge-audit-verified
// fib-badge-audit-disputed
// fib-badge-audit-claimed

const getOrderProcessScanType = (status: E_AUDIT_CLAIMED_STATUS) => {
  let badgeClassName = "";
  let filterStatus = "";
  let icon: React.ReactNode;

  switch (status) {
    case E_AUDIT_CLAIMED_STATUS.CLAIMED:
      badgeClassName = "fib-badge-audit-claimed"
      icon = checkIcon()
      filterStatus = "Claimed"
      break;
    case E_AUDIT_CLAIMED_STATUS.VERIFIED:
      badgeClassName = "fib-badge-audit-verified"
      icon = <FontAwesomeIcon icon={faInfoCircle} className={`mr-1`}/>
      filterStatus = "Unclaimed"
      break;
    case E_AUDIT_CLAIMED_STATUS.DISPUTE:
      badgeClassName = "fib-badge-audit-disputed"
      icon = checkIcon()
      filterStatus = "Verified"
      break;
    case E_AUDIT_CLAIMED_STATUS.SELF_REPORTED:
      badgeClassName = "fib-badge-audit-selfreport"
      icon = checkIcon()
      filterStatus = "Verified"
      break;
  }
  return {badgeClassName, icon, filterStatus};
}

const dataTabs: ITabs[] = [
  {
    value: StepTabs.UPDATED,
    display: "LABEL.STEP_TABS.UPDATED"
  },
  {
    value: StepTabs.UPCOMING,
    display: "LABEL.STEP_TABS.UPCOMING"
  },
  {
    value: StepTabs.ALL,
    display: "LABEL.STEP_TABS.ALL"
  }
];

const filterSteps = (steps: IHistory[], selected: number) => {
  return steps.filter((step: IHistory) => {
    if (selected === StepTabs.UPCOMING) {
      return step.process_status === StepTabs.UPCOMING
    } else if (selected === StepTabs.UPDATED) {
      return step.process_status === StepTabs.UPDATED || (step.process_status === StepTabs.MISSED && step.custom_scan_date)
    }

    return true;
  });
}

export const canViewStep = (status: number, custom_date: string) => {
  return Boolean((status === Number(StepTabs.UPDATED) || (StepTabs.MISSED && custom_date)))
}

const ProcessDateTimeComponent = ({dateTime}) => {
  const [date, time] = dateTime.split(' ');

  return (
    <>
      {date}<br/>{time}
    </>
  );
}

const OrderStepsHistoryV2 = ({
                               orderCode,
                               onSubmit
                             }: any) => {

  const dispatch = useAppDispatch();
  const langData = useAppSelector(languageState);
  const userInfo = useAppSelector(userState);

  const orderDetailStore = useAppSelector(orderDetailState);
  const orderProcessesStore = useAppSelector(orderProcessesState);

  const defaultHistory: IHistory[] = [];

  const [curHistoryData, setCurHistoryData] = useState([...defaultHistory]);
  const [select, setSelected] = useState(StepTabs.ALL);
  const [dataMap, setDatamap] = useState([] as any);
  const [auditDownloading, setAuditDownloading] = useState(false);
  const [isShowProcessPopup, setShowProcessPopup] = useState(false);
  const [isShowConfirmPopup, setShowConfirmPopup] = useState(false);
  const [curProcess, setCurProcess] = useState({} as any);
  const [deleteObj, setDeleteObj] = useState({} as any);

  /**
   * Intro steps
   */
  const [showIntro, setShowIntro] = useState(false);
  const [introSteps, setIntroSteps] = useState({
    stepsEnabled: true,
    initialStep: 0,
    steps: [
      {
        element: ".header-menu .navbar-brand img",
        intro: "Press on our logo to return to the main dashboard menu for more information, demos and tutorials."
      },
      {
        element: ".header-menu .animated-bars",
        intro: "For more information visit the Help section from the main menu"
      },
      {
        element: ".ux-wizard-trigger",
        intro: "Click here to open the Quick Start Wizard"
      },
      {
        element: ".ft-table.table-track-order table tbody tr:first",
        intro: "Select the Audit Button to conduct the Audit"
      },
    ],
    hintsEnabled: false,
    hints: []
  })

  const processesAbort = useRef<any>(null);

  const navigate = useNavigate();
  const [connectedOrders, setConnectedOrders] = useState<any>([])

  const handleClick = async (val: StepTabs) => {
    setSelected(val);

    await fetchOrderHistories({
      order_code: orderCode,
      status: val === StepTabs.UPCOMING ? "upcoming" : (val === StepTabs.UPDATED ? "updated" : null),
      isSort: val === StepTabs.UPCOMING
    })
  }

  const handleDownload = async () => {

    try {
      setAuditDownloading(true);

      dispatch(confirmationMessageSlice.actions.showMessage({
        isShow: true,
        title: "LABEL.DOWNLOAD_FILES",
        type: Message.NEUTRAL,
        message: `<p class="text-red mb-1">${translateV2("LABEL.AUDITS_CSV.DOWNLOAD_ALL", "", {'0': ''})}</p><i class="fz-13">${translateV2("LABEL.AUDITS_CSV.DOWNLOAD_ALL.NOTE", "", {'0': ''})}</i>`,
        confirmAction: {
          type: "order/downloadAuditCSV",
          value: orderCode
        },
        cancelAction: ""
      }))

    } catch (error) {
      setAuditDownloading(false);
    } finally {
      setAuditDownloading(false);
    }
  }

  const openConfirmDelete = (process: any) => {
    setShowConfirmPopup(true);
    setCurProcess(process);
  }

  const handleDeleteProcess = async () => {
    setDeleteObj({
      loading: true,
      message: ''
    });
    try {
      const {error} = await ORDER_API.deleteOrderProcess(orderCode, curProcess.process_id);
      if (error) {
        setDeleteObj({
          loading: false,
          message: error
        });
      } else {
        await fetchOrderHistories({
          ...orderProcessesStore.search.queries
        })
        // await orderSummary();
        closeConfirmDelete();
      }
      if (_.isFunction(onSubmit)) onSubmit()
    } catch (error) {
      setDeleteObj({
        loading: true,
        message: error
      });
    }
  }

  const closeConfirmDelete = () => {
    setShowConfirmPopup(false);
    setCurProcess({});
    setDeleteObj({});
  }

  const fetchOrderHistories = async ({order_code = orderCode, status = null, isSort = null}: {
    order_code?: string
    status?: null | string | undefined
    isSort?: null | string | undefined | boolean
  }) => {
    try {

      if (processesAbort.current) {
        processesAbort.current.abort();
      }

      processesAbort.current = new AbortController();

      dispatch(fetchOrderHistoriesAsync({
        order_code: orderCode,
        status,
        isSort,
        signal: processesAbort.current.signal
      }))

    } catch (e) {
      console.error(e)
    }
  }

  const canNotCreateOrderProcess = () => {
    return orderDetailStore.summary.is_completed;
  }

  return (
    <>

      {!_.isEmpty(langData.localize) && curHistoryData.length > 0
        && Boolean(JSON.parse(localStorage.getItem("done_uxwizard_setup") + "")
          && !JSON.parse(localStorage.getItem("show_uxwizard_intro") + "")) &&
          <Steps
              enabled={introSteps.stepsEnabled}
              steps={[
                {
                  element: ".header-menu .navbar-brand img",
                  intro: "Press on our logo to return to the main dashboard menu for more information, demos and tutorials."
                },
                {
                  element: ".header-menu .animated-bars",
                  intro: "For more information visit the Help section from the main menu"
                },
                {
                  element: ".ux-wizard-trigger",
                  intro: "Click here to open the Quick Start Wizard"
                },
                {
                  element: ".ft-table.table-track-order .btn-audit-process0",
                  intro: "Select the Audit Button to conduct the Audit"
                },
              ]}
              initialStep={introSteps.initialStep}
              options={{
                doneLabel: translateV2("LABEL.DONE"),
                nextLabel: translateV2("LABEL.NEXT"),
                prevLabel: translateV2("LABEL.PREVIOUS")
              }}
              onExit={() => {
                setIntroSteps({...introSteps, stepsEnabled: !introSteps.stepsEnabled})
                setShowIntro(true)
              }}
          />}

      <div className="container order-details-container">

        <div className={"mt-5 mb-4"}>
          <h1 className="text-center text-lg-left mb-0 page-title">
            {translateV2("LABEL.ORDER.PROCESSES")}
          </h1>
        </div>

        <div
          className={`d-flex justify-content-between align-items-center mb-4 flex-column flex-md-row `}>

          <Tabs selected={select} click={handleClick} data={dataTabs}
                cssClass={`navbar-nav nav nav-pills com-nav justify-content-center text-center ${isMobile ? "fpw-100" : ""}`}/>

          {canNotCreateOrderProcess() ? null :
            <Button variant={"success"}
                    className={`fibre-btn-success fibre-border-radius fibre-landscape-padding mt-3 mt-md-0 ${isMobile ? "fpw-100" : ""}`}
                    disabled={canNotCreateOrderProcess()}
                    onClick={() => {

                      dispatch(panelSlice.actions.show({
                        header: translateV2("LABEL.ORDER_PROCESS"),
                        component: EComponents.ORDER_PROCESS,
                        componentProps: {
                          action: USER_ACTION.CREATE,
                          options: {
                            ...orderDetailStore.summary,
                            orderCode: orderDetailStore.summary.order_code
                          }
                        },
                        afterClose: {
                          type: "orderProcesses/fetchHistories",
                          value: {
                            order_code: orderProcessesStore.search.queries.order_code,
                            status: orderProcessesStore.search.queries.status,
                            isSort: orderProcessesStore.search.queries.isSort
                          }
                        }
                      }))

                    }}>{translateV2("LABEL.CREATE_ORDER_PROCESS")}</Button>}

        </div>

        <div className="mt-4">
          <h5 className="h5-title">
            {translateV2("LABEL.ORDER.SCANNING_STEPS_HISTORY")}
            <button id='download-audit' className="download-audit" onClick={handleDownload}>
              {auditDownloading ? <FontAwesomeIcon icon={faSpinner} spin/> :
                <FontAwesomeIcon icon={faDownload}/>}
              {translateV2('LABEL.DOWNLOAD_AUDIT_LIST')}
            </button>
          </h5>
        </div>

        <div className="journey-container">

          <div className="journey-table">

            {/*<div className="journey-table-header">*/}

            <div className={"journey-table-header"}>

              <div className={`journey-table-header-process`}>{translateV2("LABEL.ORDER.TABLE.PROCESS")}</div>
              <div className={`journey-table-header-time`}>{translateV2("LABEL.AUDIT_DEADLINE")}</div>
              <div className={`journey-table-header-audits`}>{translateV2("LABEL.ORDER_PROCESS.AUDITS")}</div>
              <div className={`journey-table-header-location`}>{translateV2("LABEL.ORDER_PROCESS.LOCATION")}</div>
              <div className={`journey-table-header-manufacturer`}>{translateV2("LABEL.ORDER.TABLE.MANUFACTURER")}</div>
              <div className={`journey-table-header-scantype`}>{translateV2("LABEL.ORDER_PROCESS.SCAN_TYPE")}</div>
              <div className={`journey-table-header-files`}>{translateV2("LABEL.ORDER.TABLE.FILES")}</div>
              <div className={`journey-table-header-corders`}>{translateV2("LABEL.ORDER_PROCESS.CONNECTED_ORDERS")}</div>
              <div className={`journey-table-header-actions`}>{translateV2("LABEL.ACTIONS")}</div>

            </div>

            {/*</div>*/}

            <div className="journey-table-body">

              {
                orderProcessesStore.loading
                  ? <LoadingRowV2 style={{
                    backgroundColor: "transparent"
                  }}/>
                  : orderProcessesStore.data.length > 0 && orderProcessesStore.data.map((item: IHistory, index) =>

                  <OrderProcessDetailV3
                    classNameWrapper={"lv0"}
                    className={"lv0"}

                    key={_.uniqueId(`his-${index}`)}
                    orderCode={orderCode}
                    editable={orderDetailStore.summary.editable}
                    isCompleted={orderDetailStore.summary.is_completed}
                    currentHistory={orderDetailStore.histories.currentHistory}
                    item={item}
                    connectedOrders={connectedOrders}
                    onClickScanType={async () => {

                    }}
                    onClickConnectedOrders={async (isOpen, event, metadata, data) => {
                      // setConnectedOrders(_.omitBy(data, !_.isObject))
                    }}
                    onClickView={async () => {
                      navigate(`/orders/${orderCode}/${item.process_position}`)
                    }}
                    onClickEdit={async () => {
                      dispatch(panelSlice.actions.show({
                        header: item.process_name,
                        component: EComponents.ORDER_PROCESS,
                        componentProps: {
                          action: USER_ACTION.EDIT,
                          options: {
                            orderDetail: orderDetailStore.summary,
                            orderFormDetail: orderDetailStore.formDetail,
                            orderCode,
                            processID: item.process_id,
                            name: item.process_name,
                            process: item,
                            key: item.process_id,
                          }
                        },
                      }))
                    }}
                    onClickLinkOrders={async () => {
                      if (_.isEmpty(item.input_products.value)) return;
                      setCurProcess({...item})
                      console.log("inputProducts", item.input_products.value)
                      if (item.input_products.value) dispatch(linkedOrdersMessageSlice.actions.showMessage({
                        isShow: true,
                        title: "LABEL.CONNECTED_ORDERS.DESCRIPTION",
                        type: Message.NEUTRAL,
                        message: "",
                        data: item.input_products.value || []
                      }))
                    }}
                    onClickDelete={async () => {
                      openConfirmDelete(item)
                    }}

                  />
                )
              }

            </div>

          </div>


        </div>


        <div className="tab-content">
          <div className="tab-pane fade show active">





          </div>
        </div>

      </div>

      {orderProcessesStore.dataMap.length > 0 &&
          <div className="container-fluid order-details-container map-container">
              <div id="map-canvas">
                {/*<POSMap orderCode={orderCode} data={dataMap}/>*/}
                {
                  _.isEmpty(orderProcessesStore.dataMap) ? null
                    : <POSMapV2 orderCode={orderCode} data={orderProcessesStore.dataMap}/>
                }
              </div>
          </div>
      }

      <Modal style={{zIndex: 999999999}} show={isShowConfirmPopup} onHide={closeConfirmDelete}>
        <Modal.Header closeButton>
          <Modal.Title>{curProcess.process_position} - {curProcess.process_name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {translateV2(deleteObj.message || 'LABEL.CONFIRM_DELETE_PROCESS')}
        </Modal.Body>
        <Modal.Footer>
          <button type="submit" className="btn btn-red" disabled={deleteObj.loading}
                  onClick={handleDeleteProcess}>
            {deleteObj.loading && <Loading/>}
            {translateV2("LABEL.DELETE")}
          </button>
          <button type="button" className="btn-white"
                  onClick={closeConfirmDelete}>{translateV2("LABEL.CLOSE")}</button>
        </Modal.Footer>
      </Modal>

      <ModalLinkedOrders/>

    </>
  );
}

export default OrderStepsHistoryV2;
